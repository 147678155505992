import { gql, useLazyQuery, useMutation } from "@apollo/client";
import HomeLayout from "components/layouts/home-layout";
import { GetStaticPropsContext } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import SubjectSlides from "partials/home/SubjectSlides";
import Visual from "partials/home/Visual";
import ChangePasswordGuide from "partials/my/password/ChangePasswordGuide";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getAttendanceStatusCodeName,
  MeetingStatus, MeetingType,
  objCookies,
  RegistrationStatus,
  UserDefaultRole,
} from "shared/utils/CommonCode";
import styles from "./home.module.css";
import { useUserContext } from "shared/user-context";
import { Subject, SubjectByCuration } from "components/types/Subject";
import ChannelSlides from "partials/home/ChannelSlides";
import KirdContainer from "components/layouts/KirdContainer";
import { RegistrationByCuration } from "components/types/Registration";
import { dateToString } from "shared/utils/DateUtil";
import { ChannelByCuration } from "components/types/Channel";
import MeetingSlides from "partials/home/MeetingSlides";
import { DefaultRole } from "components/types/Role";
import { checkDevice, isMobileApp, isMobileBrowser } from "shared/utils/CommonUtil";
import { FRAGMENT_CLASSIFICATION } from "shared/graphql/fragments";
import { ChannelContentStatus } from "shared/utils/CommonCode";
import { ChannelContent } from "components/types/ChannelContent";
import NewChannelSlides from "partials/home/NewChannelSlides";
import KirdModal from "components/common/KirdModal";
import { Button, DialogContent, Grid } from "@material-ui/core";
import QrScanner from 'qr-scanner';
import EbookSlides from "partials/home/EbookSlides";
import { login } from "shared/api/login";

// ~를 위한 맞춤 과정
const GET_SUBJECT_LIST_BY_CURATION = gql`
  query getSubjectListByCuration(
    $request: GetSubjectListForStudentByCurationInput
  ) {
    subjects: getSubjectListByCuration(request: $request) {
      id
      type
      name
      thumbnail {
        id
        name: originFileName
        size
        contentType
      }
      companyGroups {
        id
        name
      }
      classifications {
        id
        name
        type
      }
      educationDay
      educationTime
      cost
      charger {
        id
      }
      keywords {
        id
        name
      }
      status
      sequences {
        id
        name
        applicationStartDateTime
        applicationEndDateTime
        cancelStartDateTime
        cancelEndDateTime
        studyStartDateTime
        studyEndDateTime
        fixedNumberOfStudent
        openToThePublic
        always
        alwaysStudyDay
      }
      exploreSequences(isExcludingEndTime: true) {
        id
        name
        applicationStartDateTime
        applicationEndDateTime
        cancelStartDateTime
        cancelEndDateTime
        studyStartDateTime
        studyEndDateTime
        fixedNumberOfStudent
        openToThePublic
        always
        alwaysStudyDay
      }
      favorites
      views
      myFavorite
    }
  }
`;

// (큐레이션) 지금 신청하면 좋은 과정 목록 조회
const GET_SUBJECT_LIST_OF_BEST_REGISTRATION_BY_CURATION = gql`
  query GetSubjectListOfBestRegistrationByCuration(
    $request: GetSubjectListOfBestRegistrationByCurationInput
  ) {
    subjects: getSubjectListOfBestRegistrationByCuration(request: $request) {
      id
        type
        name
        thumbnail {
          id
          name: originFileName
          size
          contentType
        }
        companyGroups {
          id
          name
        }
        classifications {
          id
          name
          type
        }
        educationDay
        educationTime
        cost
        charger {
          id
        }
        keywords {
          id
          name
        }
        status
        sequences {
          id
          name
          applicationStartDateTime
          applicationEndDateTime
          cancelStartDateTime
          cancelEndDateTime
          studyStartDateTime
          studyEndDateTime
          fixedNumberOfStudent
          openToThePublic
          always
          alwaysStudyDay
        }
        exploreSequences(isExcludingEndTime: true) {
          id
          name
          applicationStartDateTime
          applicationEndDateTime
          cancelStartDateTime
          cancelEndDateTime
          studyStartDateTime
          studyEndDateTime
          fixedNumberOfStudent
          openToThePublic
          always
          alwaysStudyDay
        }
        favorites
        views
        myFavorite
    }
  }
`;

// (큐레이션) SEO 과정 목록 조회
const GET_SUBJECT_LIST_OF_SEO_REGISTRATION_BY_CURATION = gql`
  query GetSubjectListOfSeoRegistrationByCuration(
    $request: GetSubjectListOfSeoRegistrationByCurationInput
  ) {
    subjects: getSubjectListOfSeoRegistrationByCuration(request: $request) {
     id
        type
        name
        thumbnail {
          id
          name: originFileName
          size
          contentType
        }
        companyGroups {
          id
          name
        }
        classifications {
          id
          name
          type
        }
        educationDay
        educationTime
        cost
        charger {
          id
        }
        keywords {
          id
          name
        }
        status
        sequences {
          id
          name
          applicationStartDateTime
          applicationEndDateTime
          cancelStartDateTime
          cancelEndDateTime
          studyStartDateTime
          studyEndDateTime
          fixedNumberOfStudent
          openToThePublic
          always
          alwaysStudyDay
        }
        exploreSequences(isExcludingEndTime: false) {
          id
          name
          applicationStartDateTime
          applicationEndDateTime
          cancelStartDateTime
          cancelEndDateTime
          studyStartDateTime
          studyEndDateTime
          fixedNumberOfStudent
          openToThePublic
          always
          alwaysStudyDay
        }
        favorites
        views
        myFavorite
    }
  }
`;

// (큐레이션) 지금 뜨는 핫한 과정 목록 조회
const GET_SUBJECT_LIST_OF_MOST_FAVORITE_BY_CURATION = gql`
  query GetSubjectListOfMostFavoriteByCuration(
    $request: GetSubjectListOfMostFavoriteByCurationInput
  ) {
    subjects: getSubjectListOfMostFavoriteByCuration(request: $request) {
      subject {
        id
        name
        type
        thumbnail {
          id
          name: originFileName
          size
          contentType
        }
        educationTime
        cost
        myFavorite
        favorites
        views
        sequences {
          id
          name
          applicationStartDateTime
          applicationEndDateTime
          cancelStartDateTime
          cancelEndDateTime
          studyStartDateTime
          studyEndDateTime
          fixedNumberOfStudent
          openToThePublic
          always
          alwaysStudyDay
        }
      }
      favoriteCount
    }
  }
`;

// (큐레이션) 지식 채널 목록 조회
const GET_CHANNEL_LIST_BY_CURATION = gql`
  query GetChannelListByCuration($request: GetChannelListByCurationInput) {
    channels: getChannelListByCuration(request: $request) {
      channel {
        id
        name
        description
        thumbnail {
          id
          name: originFileName
          size
          contentType
        }
        influencer {
          id
          name
        }
        keywords {
          id
          name
        }
        views
        nonLoginViews
        subscriberCount
        numberOfContent
        numberOfContentWithPlaylist
      }
      createdDateTime
    }
  }
`;

// (큐레이션) 회의 목록 조회
const GET_MEETING_LIST_BY_CURATION = gql`
  query GetMeetingListByCuration($request: GetMeetingListByCurationInput) {
    meetings: getMeetingListByCuration(request: $request) {
      id
      name
      type
      registrationStartDateTime
      registrationEndDateTime
      sessions {
        startDateTime
        endDateTime
      }
      thumbnail {
        id
        name: originFileName
        size
        contentType
      }
    }
  }
`;

// (큐레이션) 새로운 콘텐츠 조회
const SEARCH_CHANNEL_CONTENT_LIST = gql`
  query SearchChannelContent($request: SearchChannelContentInput!) {
    channelContents: searchChannelContents(request: $request) {
      content {
        id
        type
        name
        thumbnail {
          id
          name: originFileName
          size
          contentType
        }
        description
        classifications {
          ...ClassificationChildrenRecursive
        }
        keywords {
          id
          name
        }
        requestInfo {
          userId
          userName
          dateTime
        }
        approvalInfo {
          userId
          userName
          dateTime
        }
        rejectInfo {
          userId
          userName
          reason
          dateTime
        }
        status
        myFavorite
        myPlayList
        channel {
          id
          name
          influencer {
            id
            name
            loginId
          }
        }
        favorites
        views
        nonLoginViews
      }
      number
      totalPages
    }
  }
  ${FRAGMENT_CLASSIFICATION}
`;

// 나의 Live 수강 검색
const SEARCH_MY_LIVE_REGISTRATION = gql`
  query SearchMyLiveRegistration($request: SearchMyLiveRegistrationInput!) {
    lives: searchMyLiveRegistration(request: $request) {
      content {
        id
      }
      number
      totalPages
      size
    }
  }
`;

// 나의 Live 강의 검색
const SEARCH_MY_LIVE_LECTURE = gql`
  query SearchMyLiveLecture($request: SearchMyLiveLectureInput!) {
    lives: searchMyLiveLecture(request: $request) {
      content {
        id
      }
      number
      totalPages
      size
    }
  }
`;

// 출결 정보 조회
const GET_ATTENDANCE_BY_ID = gql`
  query GetAttendanceById($id: ID!) {
    attendance: getAttendanceById(id: $id) {
      id
      status
      attended
    }
  }
`;

// 출석 체크
const CHECK_ATTENDANCE_WITH_LESSON_TOKEN = gql`
  mutation CheckAttendanceWithLessonToken(
    $request: CheckAttendanceWithLessonTokenInput!
  ) {
    attendance: checkAttendanceWithLessonToken(request: $request) {
      id
    }
  }
`;

// Live 사용자 정보 조회
const GET_LIVE_MEMBER_BY_ID = gql`
  query GetLiveMemberById($request: GetLiveMemberByIdInput) {
    member: getLiveMemberById(request: $request) {
      member {
        id
        name
      }
      nickName
    }
  }
`;

// 내 출결 현황 조회
const SEARCH_MY_RECENT_REGISTRATION = gql`
  query searchMyRecentRegistration($request: SearchMyRecentRegistrationInput) {
    registration: searchMyRecentRegistration(request: $request) {
      id
    }
  }
`

export default function Home() {
  const { t } = useTranslation();
  const { query, isReady, push } = useRouter();
  const { user } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const [isClickedCamera, setIsClickedCamera] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [attendanceLink, setAttendanceLink] = useState<string>('')
  const [isIOSDevice, setIsIOSDevice] = useState(false)

  const closeModal = (setter: any) => { setter(false); }
  const videoRef = useRef(null);

  // Live.K 버튼 노출 여부
  const isShowLiveK = useMemo(() => isReady && isMobileBrowser(), [query]);

  // 출결관리 버튼 노출 여부
  const isShowAttendance = useMemo(() => isReady && isMobileApp(), [query]);

  // 권한 정보
  const role = useMemo(() => {
    let isAdmin = false;
    let isCompanyGroupManager = false;
    let isCompanyManager = false;
    let isContentProvider = false;
    let isTeacher = false;
    let isTutor = false;
    let isUser = false;

    if (user && user?.roles) {
      isAdmin =
        user.roles?.filter(
          (role: DefaultRole) => role.code === UserDefaultRole.ADMIN
        )?.length > 0;
      isUser =
        user.roles?.filter(
          (role: DefaultRole) => role.code === UserDefaultRole.USER
        )?.length > 0;

      if (!isAdmin) {
        isCompanyGroupManager =
          user.roles?.filter(
            (role: DefaultRole) =>
              role.code === UserDefaultRole.COMPANY_GROUP_MANAGER
          )?.length > 0;
        isCompanyManager =
          user.roles?.filter(
            (role: DefaultRole) => role.code === UserDefaultRole.COMPANY_MANAGER
          )?.length > 0;
        isContentProvider =
          user.roles?.filter(
            (role: DefaultRole) =>
              role.code === UserDefaultRole.CONTENT_PROVIDER
          )?.length > 0;
        isTeacher =
          user.roles?.filter(
            (role: DefaultRole) => role.code === UserDefaultRole.TEACHER
          )?.length > 0;
        isTutor =
          user.roles?.filter(
            (role: DefaultRole) => role.code === UserDefaultRole.TUTOR
          )?.length > 0;
      }
    }

    return {
      admin: isAdmin,
      companyGroupManager: isCompanyGroupManager,
      companyManager: isCompanyManager,
      contentProvider: isContentProvider,
      teacher: isTeacher,
      tutor: isTutor,
      user: isUser,
    };
  }, [user]);

  // 학습자 맞춤 과정 목록 조회
  const [
    fnGetRegistrationListForStudentByCuration,
    { data: registrationsForStudentData },
  ] = useLazyQuery(GET_SUBJECT_LIST_BY_CURATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let newExcludeSubjectIds = new Array();

      // 지금 신청하면 딱 좋은 과정 목록 조회
      fnSelectSubjectListOfBestRegistrationByCuration(newExcludeSubjectIds);
    },
  });

  // 학습자 맞춤 과정 목록
  const registrationsForStudent = registrationsForStudentData?.subjects;

  // 지금 신청하면 딱 좋은 과정 목록 조회
  const [
    fnGetSubjectListOfBestRegistrationByCuration,
    { data: subjectsOfBestRegistrationData },
  ] = useLazyQuery(GET_SUBJECT_LIST_OF_BEST_REGISTRATION_BY_CURATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let newExcludeSubjectIds = new Array();
      registrationsForStudent?.map(
        (registrationByCuration: RegistrationByCuration) =>
          newExcludeSubjectIds.push(registrationByCuration.subject?.id)
      );
      data.subjects?.map((subject: Subject) =>
        newExcludeSubjectIds.push(subject.id)
      );

      // 지금 뜨는 핫한 과정 목록 조회
      fnSelectSubjectListOfMostFavoriteByCuration(newExcludeSubjectIds);
    },
  });

  // 지금 신청하면 딱 좋은 과정 목록
  const subjectsOfBestRegistration = subjectsOfBestRegistrationData?.subjects;

  // Seo 과정 목록 조회
  const [
    fnGetSubjectListOfSeoRegistrationByCuration,
    { data: subjectsOfSeoRegistrationData },
  ] = useLazyQuery(GET_SUBJECT_LIST_OF_SEO_REGISTRATION_BY_CURATION, {
    fetchPolicy: "no-cache"
  });

  // Seo 과정 목록
  const subjectsOfSeoRegistration = subjectsOfSeoRegistrationData?.subjects;

  // 지금 뜨는 핫한 과정 목록 조회
  const [
    fnGetSubjectListOfMostFavoriteByCuration,
    { data: subjectsOfMostFavoriteData },
  ] = useLazyQuery(GET_SUBJECT_LIST_OF_MOST_FAVORITE_BY_CURATION, {
    fetchPolicy: "no-cache",
  });

  // 지금 뜨는 핫한 과정 목록
  const subjectsOfMostFavorite = subjectsOfMostFavoriteData?.subjects;

  // 새로운 채널 콘텐츠 목록 조회
  const [fnSearchNewChannelContentList, { data: newChannelContentsData }] =
    useLazyQuery(SEARCH_CHANNEL_CONTENT_LIST, { fetchPolicy: "no-cache" });

  // 새로운 채널 콘텐츠 목록
  const newChannelContents = newChannelContentsData?.channelContents;

  // 지식 채널 목록 조회
  const [
    fnGetChannelListForStudentByCuration,
    { data: channelsForStudentData },
  ] = useLazyQuery(GET_CHANNEL_LIST_BY_CURATION, { fetchPolicy: "no-cache" });

  // 지식 채널 목록
  const channelsForStudent = channelsForStudentData?.channels;

  // 연구현장 지식 커뮤니티 Meet-Up 목록 조회
  const [fnGetMeetingListByCuration, { data: meetingsData }] = useLazyQuery(
    GET_MEETING_LIST_BY_CURATION,
    { fetchPolicy: "no-cache" }
  );

  // 연구현장 지식 커뮤니티 Meet-Up 목록
  const meetings = meetingsData?.meetings;

  // 나의 Live 목록
  const [lives, setLives] = useState([]);

  // 나의 Live 수강 검색
  const [fnSearchMyLiveRegistration] = useLazyQuery(
    SEARCH_MY_LIVE_REGISTRATION,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setLives(data?.lives?.content);
      },
    }
  );

  // 나의 Live 강의 검색
  const [fnSearchMyLiveLecture] = useLazyQuery(SEARCH_MY_LIVE_LECTURE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setLives(data?.lives?.content);
      if (user?.id) {
        fnGetLiveMemberById({ variables: { request: { id: user?.id } } });
      }
    },
  });

  // Live 사용자 정보 조회
  const [fnGetLiveMemberById, { data: liveMemberData }] = useLazyQuery(
    GET_LIVE_MEMBER_BY_ID
  );
  // Live 사용자 정보
  const liveMember = liveMemberData?.member;

  // 학습자 맞춤 과정 목록 조회
  const fnSelectRegistrationListForStudentByCuration = () => {
    let getRegistrationListByCurationRequest: any = {
      studentId: String(user?.id),
      searchStartDate: dateToString(new Date().toISOString(), "yyyy-MM-dd"),
      limit: 20,
    };

    // 학습자 맞춤 과정 목록 조회
    fnGetRegistrationListForStudentByCuration({
      variables: { request: { ...getRegistrationListByCurationRequest } },
    });
  };

  // 지금 신청하면 딱 좋은 과정 목록 조회
  const fnSelectSubjectListOfBestRegistrationByCuration = (
    excludeSubjectIds?: Array<string> | null
  ) => {
    let getSubjectListOfBestRegistrationByCurationRequest: any = {
      searchStartDate: dateToString(new Date().toISOString(), "yyyy-MM-dd"),
      searchEndDate: dateToString(new Date().toISOString(), "yyyy-MM-dd"),
      limit: 20,
    };
    if (user?.authenticated) {
      getSubjectListOfBestRegistrationByCurationRequest = {
        ...getSubjectListOfBestRegistrationByCurationRequest,
        studentId: String(user?.id),
      };
    }
    if (excludeSubjectIds?.length > 0) {
      getSubjectListOfBestRegistrationByCurationRequest = {
        ...getSubjectListOfBestRegistrationByCurationRequest,
        excludeSubjectIds: excludeSubjectIds,
      };
    }

    // 지금 신청하면 딱 좋은 과정 목록 조회
    fnGetSubjectListOfBestRegistrationByCuration({
      variables: {
        request: { ...getSubjectListOfBestRegistrationByCurationRequest },
      },
    });
  };

  // Seo은 과정 목록 조회
  const fnSelectSubjectListOfSeoRegistrationByCuration = (
      includeSubjectIds?: Array<string> | null
  ) => {
    let getSubjectListOfSeoRegistrationByCurationRequest: any = {
      searchEndDate: dateToString(new Date().toISOString(), "yyyy-MM-dd"),
      limit: 20,
    };
    if (user?.authenticated) {
      getSubjectListOfSeoRegistrationByCurationRequest = {
        ...getSubjectListOfSeoRegistrationByCurationRequest,
        studentId: String(user?.id),
      };
    }
    if (includeSubjectIds?.length > 0) {
      getSubjectListOfSeoRegistrationByCurationRequest = {
        ...getSubjectListOfSeoRegistrationByCurationRequest,
        includeSubjectIds: includeSubjectIds,
      };
    }

    // 지금 신청하면 딱 좋은 과정 목록 조회
    fnGetSubjectListOfSeoRegistrationByCuration({
      variables: {
        request: { ...getSubjectListOfSeoRegistrationByCurationRequest },
      },
    });
  };

  // 지금 뜨는 핫한 과정 목록 조회
  const fnSelectSubjectListOfMostFavoriteByCuration = (
    excludeSubjectIds?: Array<string> | null
  ) => {
    let now = new Date();
    let nowYear = now.getFullYear();
    let nowMonth = now.getMonth();
    let nowDate = now.getDate();
    let searchStartYear = nowYear;
    let searchStartMonth = nowMonth;
    let searchStartDate = nowDate;

    if (nowMonth <= 3) {
      searchStartYear = nowYear - 1;
      searchStartMonth = nowMonth + (12 - 3);
    }

    let getSubjectListOfMostFavoriteByCurationRequest: any = {
      searchStartDate: dateToString(
        new Date(
          searchStartYear,
          searchStartMonth,
          searchStartDate
        ).toDateString(),
        "yyyy-MM-dd"
      ),
      searchEndDate: dateToString(
        new Date(nowYear, nowMonth, nowDate).toDateString(),
        "yyyy-MM-dd"
      ),
      limit: 20,
    };
    if (user?.authenticated) {
      getSubjectListOfMostFavoriteByCurationRequest = {
        ...getSubjectListOfMostFavoriteByCurationRequest,
        studentId: String(user?.id),
      };
    }
    if (excludeSubjectIds?.length > 0) {
      getSubjectListOfMostFavoriteByCurationRequest = {
        ...getSubjectListOfMostFavoriteByCurationRequest,
        excludeSubjectIds: excludeSubjectIds,
      };
    }

    // 지금 뜨는 핫한 과정 목록 조회
    fnGetSubjectListOfMostFavoriteByCuration({
      variables: {
        request: { ...getSubjectListOfMostFavoriteByCurationRequest },
      },
    });
  };

  useEffect(() => {
    if (isReady) {
      ///////////////////////////////////////////////////////////////////////////
      // 과정 목록 조회
      ///////////////////////////////////////////////////////////////////////////
      if (user?.authenticated) {
        // 학습자 맞춤 과정 목록 조회
        fnSelectRegistrationListForStudentByCuration();
      } else {
        // 지금 신청하면 딱 좋은 과정 목록 조회
        fnSelectSubjectListOfBestRegistrationByCuration(null);
      }
      ///////////////////////////////////////////////////////////////////////////

      // SEO 과정 하드코딩 ---> 해당 배열에 SEO 캐러셀에 들어갈 과정의 ID 값을 넣으면됨
      let includesSubjectIds = ['56f5f8f9-0ae5-4da3-81a7-10ecf9bb9e68','c8bae557-19a9-47fe-a944-08fe017d2219','cf389ffb-3709-4b3b-b9b6-9613b775a555',
      'd6bbe232-a52e-4db7-9c5e-0cf0fa47e5a7','6afe7df0-2614-44d7-8d65-a63726f271ae','6d5720d0-4c3e-4ae5-ac6c-4d7be0dab200'];
      fnSelectSubjectListOfSeoRegistrationByCuration(includesSubjectIds);
      ///////////////////////////////////////////////////////////////////////////
      // 내가 구독한 지식 채널 목록 조회
      ///////////////////////////////////////////////////////////////////////////
      let getChannelListForStudentByCurationRequest: any = {
        limit: 20,
      };
      if (user?.authenticated) {
        getChannelListForStudentByCurationRequest = {
          ...getChannelListForStudentByCurationRequest,
          studentId: String(user?.id),
        };
      }

      fnGetChannelListForStudentByCuration({
        variables: {
          request: { ...getChannelListForStudentByCurationRequest },
        },
      });
      ///////////////////////////////////////////////////////////////////////////

      ///////////////////////////////////////////////////////////////////////////
      // 연구현장 지식 커뮤니티 Meet-Up 검색
      ///////////////////////////////////////////////////////////////////////////
      let getMeetingListByCurationRequest: any = {
        status: MeetingStatus.APPROVED,
        limit: 20,
        type: MeetingType.SEMINAR,
        location : 'Main'
      };
      if (user?.authenticated) {
        getMeetingListByCurationRequest = {
          ...getMeetingListByCurationRequest,
          studentId: String(user?.id),
        };
      }

      fnGetMeetingListByCuration({
        variables: { request: { ...getMeetingListByCurationRequest } },
      });
      ///////////////////////////////////////////////////////////////////////////

      // 새로운 채널 콘텐츠 목록 조회
      fnSearchNewChannelContentList({
        variables: {
          request: {
            status: ChannelContentStatus.APPROVED,
            publicYn: "true",
            page: {
              number: 0,
              size: 20,
              sort: [
                {
                  property: "approvalInfo.dateTime",
                  direction: "desc",
                },
              ],
            },
          },
        },
      });

      if (role.teacher || role.tutor) {
        ///////////////////////////////////////////////////////////////////////////
        // 나의 Live 강의 검색
        ///////////////////////////////////////////////////////////////////////////
        let searchMyLiveLectureRequest: any = {
          statuses: [
            RegistrationStatus.APPROVED,
            RegistrationStatus.NOT_COMPLETED,
            RegistrationStatus.COMPLETED,
          ],
          studying: true,
          page: {
            number: 0,
            size: 1,
            sort: [
              {
                property: "createdDateTime",
                direction: "desc",
              },
            ],
          },
        };

        // 나의 Live 강의 검색
        fnSearchMyLiveLecture({
          variables: { request: { ...searchMyLiveLectureRequest } },
        });
        ///////////////////////////////////////////////////////////////////////////
      } else {
        ///////////////////////////////////////////////////////////////////////////
        // 나의 Live 수강 검색
        ///////////////////////////////////////////////////////////////////////////
        let searchMyLiveRegistrationRequest: any = {
          statuses: [
            RegistrationStatus.APPROVED,
            RegistrationStatus.NOT_COMPLETED,
            RegistrationStatus.COMPLETED,
          ],
          studying: true,
          page: {
            number: 0,
            size: 1,
            sort: [
              {
                property: "createdDateTime",
                direction: "desc",
              },
            ],
          },
        };

        // 나의 Live 수강 검색
        fnSearchMyLiveRegistration({
          variables: { request: { ...searchMyLiveRegistrationRequest } },
        });
        ///////////////////////////////////////////////////////////////////////////
      }
      if (!objCookies.get({ name: "popup" })) {
        setOpenPopup(true);
      }

      // NOTE: lsh 내 최근 오프라인 과정 수강 조회
      fnSearchMyRecentRegistration({variables: {request: {id: user?.id}}});
    }
  }, [query, user]);

  // 출결 현황 조회
  const [fnGetAttendanceById] = useLazyQuery(GET_ATTENDANCE_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.attendance) {
        enqueueSnackbar(
          `${
            getAttendanceStatusCodeName[data.attendance.status]
          } 처리되었습니다.`,
          {
            variant: "success",
          }
        );
      }
    },
  });

  // 출석 체크
  const [checkAttendanceWithLessonToken] = useMutation(
    CHECK_ATTENDANCE_WITH_LESSON_TOKEN,
    {
      onCompleted: async (data) => {
        if (data?.attendance) {
          // 출결 현황 조회
          fnGetAttendanceById({ variables: { id: data.attendance.id } });
        } else {
          enqueueSnackbar(`출결에 실패하였습니다.`, {
            variant: "error",
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(
          error.message?.replace("java.lang.RuntimeException:", "") ||
            `출결에 실패하였습니다.`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  // 내 오프라인 출결 현황 조회
  const [fnSearchMyRecentRegistration, {data: recentRegistrationData}] = useLazyQuery(SEARCH_MY_RECENT_REGISTRATION, {fetchPolicy: "no-cache",});

  // NOTE: lsh 최근 과정의 id
  const recentRegistrationID = recentRegistrationData?.registration?.id


  // 출석 체크
  const fnAttendance = (token: string) => {
    if (!token) return;

    // 출석 체크
    checkAttendanceWithLessonToken({
      variables: { request: { token: token } },
    });
  };

  // 출결 콜백 URL
  const [callbackUrl, setCallbackUrl] = useState<string | null>(null);

  // 출결 관리
  const fnOpenAttendanceManager = async () => {
    window.FirbMobile.request.qrcode((url: string) => setCallbackUrl(url));
  };

  // //TODO: lsh 23.07.16 16:00시 이후에 사라지게 하는 소스 / 다음 반영때 삭제 해야함
  // 현재 state 값
  const [closePopup, setClosePopup] = useState<boolean>(false);

  // 주어진 날짜와 시간
  const targetDate = new Date("2023-12-20T19:00:00");

  // 주어진 날짜와 시간에 state 값을 변경하는 함수
  const updateStateOnDate = () => {
    const currentDate = new Date(); // 현재 날짜와 시간
    if (currentDate <= targetDate && !closePopup) {
      setClosePopup(true);
    }
  };

  // 일정 간격으로 updateStateOnDate 함수 호출 (예: 1초마다)
  if (!closePopup) {
    setInterval(updateStateOnDate, 1000);
  }

  useEffect(() => {
    if (callbackUrl) {
      // 출석 체크
      fnAttendance(callbackUrl);
    }
  }, [callbackUrl]);

  // 팝업 시간 설정
  const startDate = new Date("2023-09-13 18:00:00").getTime() / 1000; // yyyy-mm-dd hh:mm:ss 로 기재
  const nowDate = new Date().getTime() / 1000;
  const endDate = new Date("2023-09-13 22:00:00").getTime() / 1000;

  const handleScan = (result: QrScanner.ScanResult) => {
    result.data && setAttendanceLink(result.data)
  };

  const QrOptions = {
    // 핸드폰의 경우, 외부 카메라인지 셀프카메라인지
    preferredCamera: 'environment',
    // 1초당 몇번의 스캔을 할 것인지? ex) 1초에 5번 QR 코드 감지한다.
    maxScansPerSecond: 5,
    // QR 스캔이 일어나는 부분을 표시해줄 지 (노란색 네모 테두리가 생긴다.)
    highlightScanRegion: true,
  };

  const fnCheckRecentStudy = () => {
    if(!recentRegistrationID) {
      return !user?.loginId ? login() : enqueueSnackbar(`학습중인 과정이 없습니다.`, { variant: "error" })
    } else {
      return push(`/study/${recentRegistrationID}/content`)
    }
  }

  useEffect(() => {
    const videoElem = videoRef.current;
    if(isClickedCamera && !videoRef.current) return;

    if (videoElem) {
      const qrScanner = new QrScanner(videoElem, (result) => handleScan(result), QrOptions);

      qrScanner.start();

      return () => qrScanner.destroy();
    }
  }, [isClickedCamera]);

  useEffect(() => {
    if (startDate <= nowDate && nowDate <= endDate) {
      setOpenPopup(false);
    }
    if(checkDevice().ios) setIsIOSDevice(true)
  }, []);

  return (
    <HomeLayout>
      <Head>
        <title>과학기술인 알파캠퍼스</title>
        <meta name="description" content="과학기술정보통신부 산하 과학기술인 대상 교육 및 경력개발을 지원하는 인재개발 종합기관"/>
        <script type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    "itemListElement": [
                      {
                        "@type": "ListItem",
                        "position": 1,
                        "item": {
                          "@type": "Course",
                          "url":"https://alpha-campus.kr/explore/56f5f8f9-0ae5-4da3-81a7-10ecf9bb9e68",
                          "name": "대학원생을 위한 연구윤리(인문사회계)",
                          "description": "알파캠퍼스에서 제공하는 과정입니다.",
                          "image" : "https://alpha-campus.kr/api/file/bbb316ed-224a-4bbf-89ea-7e8dc1e8b0b0?accessToken=ok",
                          "provider": {
                            "@type": "Organization",
                            "name": "과학기술인 알파캠퍼스",
                            "sameAs": "https://alpha-campus.kr"
                          }
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 2,
                        "item": {
                          "@type": "Course",
                          "url":"https://alpha-campus.kr/explore/c8bae557-19a9-47fe-a944-08fe017d2219",
                          "name": "대학원생을 위한 연구윤리(이공계)",
                          "description": "알파캠퍼스에서 제공하는 과정입니다.",
                          "image": "https://alpha-campus.kr/api/file/207a1477-b8f2-46d4-afd9-31f0263e0666?accessToken=ok",
                          "provider": {
                            "@type": "Organization",
                            "name": "과학기술인 알파캠퍼스",
                            "sameAs": "https://alpha-campus.kr"
                          }
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 3,
                        "item": {
                          "@type": "Course",
                          "url":"https://alpha-campus.kr/explore/cf389ffb-3709-4b3b-b9b6-9613b775a555",
                          "name": "연구책임자를 위한 연구윤리(인문사회계)",
                          "description": "알파캠퍼스에서 제공하는 과정입니다.",
                          "image": "https://alpha-campus.kr/api/file/65dc24c8-7d8c-425a-8cf5-e048a03df77f?accessToken=ok",
                          "provider": {
                            "@type": "Organization",
                            "name": "과학기술인 알파캠퍼스",
                            "sameAs": "https://alpha-campus.kr"
                          }
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 4,
                        "item": {
                          "@type": "Course",
                          "url":"https://alpha-campus.kr/explore/d6bbe232-a52e-4db7-9c5e-0cf0fa47e5a7",
                          "name": "연구책임자를 위한 연구윤리(이공계)",
                          "description": "알파캠퍼스에서 제공하는 과정입니다.",
                          "image": "https://alpha-campus.kr/api/file/50a6cd3f-0a7a-4461-ac04-d1f36a302269?accessToken=ok",
                          "provider": {
                            "@type": "Organization",
                            "name": "과학기술인 알파캠퍼스",
                            "sameAs": "https://alpha-campus.kr"
                          }
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 5,
                        "item": {
                          "@type": "Course",
                          "url":"https://alpha-campus.kr/explore/6afe7df0-2614-44d7-8d65-a63726f271ae",
                          "name": "대학(원)생을 위한 연구보안관리",
                          "description": "알파캠퍼스에서 제공하는 과정입니다.",
                          "image": "https://alpha-campus.kr/api/file/3210702d-311a-42c7-bf77-539d6642c949?accessToken=ok",
                          "provider": {
                            "@type": "Organization",
                            "name": "과학기술인 알파캠퍼스",
                            "sameAs": "https://alpha-campus.kr"
                          }
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 6,
                        "item": {
                          "@type": "Course",
                          "url":"https://alpha-campus.kr/explore/6d5720d0-4c3e-4ae5-ac6c-4d7be0dab200",
                          "name": "연구보안관리 기본(연구책임자 및 참여연구원)",
                          "description": "알파캠퍼스에서 제공하는 과정입니다.",
                          "image": "https://alpha-campus.kr/api/file/64673a9b-9651-4365-84bf-9fe0102ed4f6?accessToken=ok",
                          "provider": {
                            "@type": "Organization",
                            "name": "과학기술인 알파캠퍼스",
                            "sameAs": "https://alpha-campus.kr"
                          }
                        }
                      }
                    ]
                  }),
                }}
        />
      </Head>
      <main id="kirdContainer">
        {/* 비밀번호 변경 안내 영역 START */}
        <ChangePasswordGuide user={user} />
        {/* 비밀번호 변경 안내 영역 END */}

        {/* 비쥬얼 영역 START - 배너(banner) */}
        <Visual userName={user?.name || ""} />
        {/* 비쥬얼 영역 END */}
        <div style={{ padding: "0 20px" }}>
          {/* 맞춤 과정 추천 영역 START */}
          {user?.authenticated &&
            registrationsForStudent?.map(
              (registrationByCuration: RegistrationByCuration) =>
                registrationByCuration?.subject
            )?.length > 0 && (
              <SubjectSlides
                id="registrationListForStudentSlide"
                referer="main"
                title={`${user?.name} ${t("님을 위한 맞춤 과정 추천")}`}
                list={registrationsForStudent}
              />
            )}
          {/* 맞춤 과정 추천 영역 END */}

          {/* SEO 과정 영역 START ---> 볼 필요 없어서 숨김 처리 했습니다 */}
          {subjectsOfSeoRegistration?.length > 0 && (
              <div style={{display:"none"}}>
              <SubjectSlides
                  id="subjectListOfSeoRegistrationSlide"
                  referer="main"
                  title={t("Seo 과정")}
                  list={subjectsOfSeoRegistration}
              />
              </div>
          )}
          {/* SEO 과정 영역 END */}

          {/* 지금 신청하면 딱 좋은 과정 영역 START */}
          {subjectsOfBestRegistration?.length > 0 && (
            <SubjectSlides
              id="subjectListOfBestRegistrationSlide"
              referer="main"
              title={t("지금 신청하면 딱 좋은 과정")}
              list={subjectsOfBestRegistration}
            />
          )}
          {/* 지금 신청하면 딱 좋은 과정 영역 END */}

          {/* 지금 뜨는 핫한 과정 영역 START */}
          {subjectsOfMostFavorite?.map(
            (subjectByCuration: SubjectByCuration) => subjectByCuration?.subject
          )?.length > 0 && (
            <SubjectSlides
              id="subjectListOfMostFavoriteSlide"
              referer="main"
              title={t("지금 뜨는 핫한 과정")}
              list={subjectsOfMostFavorite?.map(
                (subjectByCuration: SubjectByCuration) =>
                  subjectByCuration?.subject
              )}
            />
          )}
          {/* 지금 뜨는 핫한 과정 영역 END */}

          {/* 새로 올라온 지식 콘텐츠 영역 START */}
          {newChannelContents?.content.map(
            (newChannelContent: ChannelContent) => newChannelContent
          )?.length > 0 && (
            <NewChannelSlides
              id="newChannelsSlide"
              title={t("새로 올라온 지식 콘텐츠")}
              list={newChannelContents?.content.map(
                (newChannelContent: ChannelContent) => newChannelContent
              )}
            />
          )}
          {/*새로 올라온 지식 콘텐츠 영역 END */}

        {/* 지식 채널 영역 START */}
        {channelsForStudent?.map(
          (channelByCuration: ChannelByCuration) => channelByCuration?.channel
        )?.length > 0 && (
          <ChannelSlides
            id="channelsSlide"
            title={t("구독 채널")}
            list={channelsForStudent?.map(
              (channelByCuration: ChannelByCuration) =>
                channelByCuration?.channel
            )}
          />
        )}
        {/* 지식 채널 영역 END */}

          {/* 연구현장 지식 커뮤니티 Meet-Up 영역 START */}
          {meetings?.length > 0 && (
            <MeetingSlides
              id="meetingsSlide"
              title={t("연구현장 지식 커뮤니티 Meet-Up")}
              list={meetings}
            />
          )}
          {/* 연구현장 지식 커뮤니티 Meet-Up 영역 END */}

          {/* 이달의 전자책 START */}
            <EbookSlides
              id="ebookSlide"
              title={t("이달의 전자책")}
              from={true}
            />
          {/* 이달의 전자책  END */}

          {/* Live.K 및 출결관리 영역 START */}
          <div className={styles.lyMinBtn}>
            {lives && lives?.length > 0 && isShowLiveK && (
              <a
                className={styles.mb_live}
                onClick={() => {
                  if (role.teacher || role.tutor) {
                    push("/livek/tutor");
                  } else if (liveMember?.nickName) {
                    push("/livek/student");
                  } else {
                    push("/livek/student/nickName");
                  }
                }}
              >
                Live.K
              </a>
            )}
            {isShowAttendance && (
              <a className={styles.mb_attend} onClick={fnOpenAttendanceManager}>
                {t("출결관리")}
              </a>
            )}
          </div>
          {/* Live.K 및 출결관리 영역 END */}

          {/* 바로가기 영역 START */}

          <KirdContainer>
            <div className={styles.lytMainMore}>
              <div className={styles.moreArea}>
                <div className={styles.moreTitGroup}>
                  <div className={styles.title}>
                    <h3>{t("바로가기")}</h3>
                  </div>
                </div>
                <div className={styles.moreContGroup}>
                  <ul className={styles.menuGroup}>
                    {/* <li className={styles.menuList}>
                                          <h4><a href="#">연간교육일정</a></h4>
                                      </li>
                                      <li className={styles.menuList}>
                                          <h4><a href="#">교육사업</a></h4>
                                      </li>
                                      <li className={styles.menuList}>
                                          <h4><a href="#">학습문의</a></h4>
                                      </li> */}
                    {user?.authenticated && (
                      <li className={styles.menuList}>
                        <h4>
                          <button
                            className="cursor-pointer"
                            onClick={() => push("/my/history")}
                          >
                            {t("수료증 발급")}
                          </button>
                        </h4>
                      </li>
                    )}
                    {/* <li className={styles.menuList}>
                                          <h4><a className="cursor-pointer" onClick={() => move('/teacher/guide')}>교강사 지원</a></h4>
                                      </li> */}
                    <li className={styles.menuList}>
                      <h4>
                        <a
                          className="cursor-pointer"
                          download="2024_KIRD_교육안내책자.pdf"
                          href="/pdf/2024_KIRD_교육안내책자.pdf"
                        >
                          {t("KIRD 교육안내책자")}{" "}
                        </a>
                      </h4>
                    </li>
                    {/* 수정
                    <li className={styles.menuList}>
                      <h4>
                        <a
                          className="cursor-pointer"
                          download="교육신청_가이드.pdf"
                          href="/pdf/교육신청_가이드.pdf"
                        >
                          {t("교육신청 가이드")}{" "}
                        </a>
                      </h4>
                    </li>
                    */}

                    <li className={styles.menuList}>
                      <h4>
                        <a
                          className="cursor-pointer"
                          download="알파캠퍼스 활용 매뉴얼.pdf"
                          href="/pdf/알파캠퍼스_활용_매뉴얼.pdf"
                        >
                          {t("알파캠퍼스 활용 매뉴얼")}
                        </a>
                      </h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </KirdContainer>
          {/* 바로가기 영역 END */}
          <button className={styles.qrBtn} onClick={()=>setIsOpenModal(true)}>
            <img src="/images/icon/icon_qrcode.svg" alt="qr코드 이미지"/>
            <p>출결현황</p>
          </button>
          <KirdModal title="출결 관리" fullWidth maxWidth="sm" open={isOpenModal} onClose={() => closeModal(setIsOpenModal)}>
            <DialogContent dividers>
              { isClickedCamera ?
              <Grid container justify="center" className={styles.attendanceItem} >
                <video ref={videoRef} style={{width: "100%", height: "50%", objectFit: "cover"}}></video>
                <div className={styles.buttonWrap}>
                  <Button color="secondary" variant="contained" onClick={() => {
                    setIsClickedCamera(false)
                    setAttendanceLink(null)}}>이전 화면</Button>
                  <Button color="primary" variant="contained" onClick={()=> push(attendanceLink)} disabled={!attendanceLink}>링크로 이동</Button>
                </div>
                <h3>{attendanceLink}</h3>
              </Grid>
              :
              <Grid container justify="center" className={styles.attendanceItem} >
                  <Grid item onClick={() => isIOSDevice ? enqueueSnackbar(`Apple 사의 보안 정책 제한으로 Apple 사용자의 경우, 기본 카메라로 QR 출석을 이용해 주시기 바랍니다.`, { variant: "error" }) : setIsClickedCamera(true) } className={styles.item}>
                    <img src="/images/icon/ico-qr-camera.svg" alt="QR출석을 위한 카메라 아이콘"/>
                    QR 출석
                  </Grid>
                  <Grid item className={styles.item} onClick={() => fnCheckRecentStudy()}>
                    <img src="/images/icon/ico-attendance.svg" alt="QR출석을 위한 카메라 아이콘"/>
                    출석 확인
                  </Grid>
              </Grid>
              }


            </DialogContent>
          </KirdModal>

          {/* KIRD 팝업 */}
          {/* NOTE: lsh 초기상태 false에서 화면 로딩 후 쿠키에서 popup 확인해서 있으면 openPopup을 true로 변경하게 수정 */}
          {/* {openPopup && <HomeKirdPopup setOpenPopup={setOpenPopup} />} */}
        </div>
      </main>
    </HomeLayout>
  );
}

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => ({
  props: {
    ...(await serverSideTranslations(locale!, ["common", "footer"])),
  },
});

